<template>
  <div class="demand-hall">
    <div class="dh-head">
      <div class="dh-button" @click="goEnterPriseCenter"></div>
      <div class="dp-flex">
        <div
            :class="['display-flex-center','dh-select',selectDemandType === item.requirementType ? 'dh-select-check' : '',]"
            v-for="(item,index) in demandType" :key="index"
            @click="changeDemandType(item.requirementType)"
        >
          {{ item.requirementTypeName }}
        </div>
      </div>
    </div>
    <div style="height: 15px"></div>
    <List item-layout="vertical" :split="false" :loading="listLoading">
      <ListItem v-for="(item,index) in demandList" :key="index">
        <router-link :to="{path: 'demandDetail',query:{'id':item.id}}" target="_blank" class="dh-list">
          <div class="dhl-left">
            <div class="dhll-title dp-flex flex-align-center">
              <div :class="getDemandTypeClass">{{ item.requirementTypeName }}</div>
              <span class="ellipse-1" style="margin-left: 10px">{{ item.title }}</span>
            </div>
            <div class="dhll-content ellipse-1">{{ item.content }}</div>
            <div class="dhll-time">{{ item.updatedTime }}</div>
          </div>
          <div class="dhl-right dp-flex flex-align-center">预算金额：<span style="color: rgba(255, 114, 0, 1)">协商确定</span>
          </div>
          <div class="dh-position" v-if="pageNum===1&&index<4">
            <div class="dh-position-bg display-flex-center">最新</div>
            <div class="dh-position-circle" />
          </div>
        </router-link>
      </ListItem>
    </List>

    <div style="display: flex;flex-direction: row-reverse;padding: 40px 20px">
      <Page :total="totalNum" :current="pageNum" :page-size="8" show-elevator @on-change="onPageChange"/>
    </div>

  </div>
</template>

<script>
import {getDemandListByType, getDemandTypeList} from "../../plugins/api/demandApi";
import {mapGetters} from "vuex";

export default {
  name: "DemandHall",
  data() {
    return {
      selectDemandType: '',
      demandType: [],
      demandList: [],//最新需求列表
      listLoading: true,//是否在加载中
      pageNum: 1,//当前页
      totalNum: 0,//总个数
    }
  },
  mounted() {
    this.getDemandType();
  },
  computed: {
    ...mapGetters({
      token: 'userStore/token',
      userName: 'userStore/userName',
      userId: 'userStore/userId',
      enterpriseState: 'userStore/enterpriseState'
    }),
    getDemandTypeClass() {
      switch (this.selectDemandType) {
        case "PROJECT_DECLARATION":
          return 'dh-list-tagA'
        case "PATENT_APPLICATION":
          return 'dh-list-tagB'
        case "INTELLECTUAL_PROPERTY_RIGHT":
          return 'dh-list-tagC'
        case "LEGAL_AID":
          return 'dh-list-tagD'
        case "ANALYSIS_OF_PATENT_INFORMATION":
          return 'dh-list-tagE'
        case "EVALUATION_OF_PATENT_VALUE":
          return 'dh-list-tagF'
        case "TECHNICAL_AUTOMATIC_MATCHING":
          return 'dh-list-tagG'
        case "OTHER_REQUIREMENTS":
          return 'dh-list-tagH'
      }
    }
  },
  methods: {
    changeDemandType(type) {
      this.pageNum = 1;
      this.selectDemandType = type;
      this.getDemandList();
    },
    //获取需求分类
    async getDemandType() {
      const json = await getDemandTypeList();
      if (json && json.code === 0 && json.result && json.result.length > 0) {
        this.demandType = json.result
        this.selectDemandType = json.result[0].requirementType
        this.getDemandList();
      }
    },
    //获取最新需求
    async getDemandList() {
      this.listLoading = true;
      const params = {
        pageSize: 8,
        pageNum: this.pageNum,
        requirementType: this.selectDemandType
      }
      const json = await getDemandListByType(params).catch(() => this.listLoading = false);
      if (json && json.code === 0) {
        if (json.result && json.result.list && json.result.list.length > 0) {
          this.demandList = json.result.list
          this.totalNum = json.result.total;
        }
      }
      this.listLoading = false;
    },
    //分页器变化监听
    onPageChange(pageNum) {
      this.pageNum = pageNum;
      this.getDemandList();
    },
    //校验是否登录
    checkIsLogin() {
      if (!this.token) {
        this.$router.push({
          path: `/login`,
          params: {
            type: 1
          }
        });
        return
      }
      if (this.enterpriseState === 'UNCERTIFIED') {
        this.$router.push({
          path: '/home/EnterpriseCertification'
        });
      }
    },
    goEnterPriseCenter() {
      this.checkIsLogin()
      this.enterpriseState === 'CERTIFIED' && this.token && this.$router.push({
        path: "/enterprise/enterprise-school-connection",
      });
    },
  }
}
</script>

<style scoped lang="scss">
/deep/ .ivu-list-item {
  padding: 0;
}

.demand-hall {
  width: 1200px;
  margin: 30px auto 50px auto;
  box-shadow: 0 4px 5px 0 rgba(136, 176, 240, 0.15);
  border-radius: 10px;
}

.dh-head {
  height: 250px;
  background-image: url("https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_demand_hall_bg.png");
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.dh-button {
  position: absolute;
  left: 487px;
  top: 98px;
  width: 104px;
  height: 28px;
  border-radius: 14px;
  cursor: pointer;
}

.dh-select {
  width: 130px;
  height: 37px;
  background: #5992E9;
  border-radius: 6px 6px 0 0;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  margin-left: 1px;
}

.dh-select:hover{
  color: #1969E5;
  background: #ABC8F4;
  border-radius: 6px 6px 0px 0px;
}
.dh-select-check {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(15, 98, 223, 1);
}

.dh-list {
  display: flex;
  flex-direction: row;
  height: 148px;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  padding: 30px;
  justify-content: space-between;
  position: relative;
}

.dh-list:hover {
  box-shadow: 0 0 5px 5px rgba(229, 239, 249, 1);
}

.dhl-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.dhll-title {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}

.dhll-content {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  max-width: 800px;
}

.dhll-time {
  font-size: 14px;
  font-weight: 400;
  color: #859BBD;
}

.dhl-right {
  font-size: 15px;
  font-weight: 400;
  color: #859BBD;
}

.dh-list-tagA {
  padding: 4px 7px;
  background: #CEDFF8;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #1062DF;
  flex-shrink: 0;
}

.dh-list-tagB {
  padding: 4px 7px;
  background: rgba(222, 206, 250, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(138, 74, 245, 1);
  flex-shrink: 0;
}

.dh-list-tagC {
  padding: 4px 7px;
  background: rgba(186, 238, 232, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(15, 178, 161, 1);
  flex-shrink: 0;
}

.dh-list-tagD {
  padding: 4px 7px;
  background: rgba(253, 221, 212, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(244, 107, 70, 1);
  flex-shrink: 0;
}

.dh-list-tagE {
  padding: 4px 7px;
  background: rgba(206, 250, 220, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(26, 181, 75, 1);
  flex-shrink: 0;
}

.dh-list-tagF {
  padding: 4px 7px;
  background: rgba(212, 215, 246, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(87, 98, 241, 1);
  flex-shrink: 0;
}

.dh-list-tagG {
  padding: 4px 7px;
  background: rgba(245, 242, 210, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(169, 159, 50, 1);
  flex-shrink: 0;
}

.dh-list-tagH {
  padding: 4px 7px;
  background: rgba(253, 219, 246, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(244, 16, 198, 1);
  flex-shrink: 0;
}

.dh-position{
  position: absolute;
  right: 20px;
  top: -5px;
  display: flex;
  flex-direction: row;
}

.dh-position-bg{
  width: 50px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  background-color: rgba(239,102,51,1);
}

.dh-position-circle{
  width: 0;
  height: 0;
  border-bottom: 5px solid rgba(239,102,51,1);
  border-right: 5px solid transparent;
}
</style>
